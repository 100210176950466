import React from 'react'
import { graphql } from 'gatsby'
import ComponentsManager from '../utils/components-manager'
import Layout from '../layout/layout'
import SEO from "./seo"


/**
 * Landing page content type template
 * @param props
 * @returns {*}
 * @constructor
 */
const BlogPost = (props) => {
    let node = props.data.nodeBlogPost;
    const img = node.relationships.field_blog_image &&
                node.relationships.field_blog_image.localFile &&
                node.relationships.field_blog_image.localFile.childImageSharp &&
                node.relationships.field_blog_image.localFile.childImageSharp.fixed &&
                node.relationships.field_blog_image.localFile.childImageSharp.fixed.src;

    return(
        <Layout invertHeader={true}>
            <SEO title={node.title} description={node.field_summary || 'Read our blog to get tips and best practices on navigating college admissions, and stay up-to-date on the latest news and updates from Common App.'} image={img} article={true} />
            <article>
                <ComponentsManager node={node}
                                   breadcrumbCategory={props.pageContext.breadcrumbCategory}
                                   components={node.relationships.field_components} />
            </article>
        </Layout>
    )
};
export default BlogPost

/**
 * Landing page query direct node
 *
 * @NOTE Don't forget to wire component fragments!
 */
export const BlogPostQuery = graphql`
query BlogPostQuery($drupalNodeId: Int!) {
  nodeBlogPost(drupal_internal__nid: {eq: $drupalNodeId})
  {
    title
    field_blog_author
    field_summary
    created
    created_formatted:created(formatString: "MMMM DD, YYYY")
    
    
    relationships {
    
      field_blog_image {
          localFile {
              childImageSharp {
                fixed(width:300) {
                  src
                }
              }
          }
      }
    
      field_blog_audience {
        id: drupal_internal__tid
        name
      }
      field_blog_tags {
        id: drupal_internal__tid
        name
      }
    
      field_components {
        __typename
        
        ...ParagraphsFixedContent
        ...ParagraphsSingleCardGradientBorderB
        ...ParagraphsSingleCardGradientBorder
        ...ParagraphsBluePhotoBanner
        ...ParagraphNewsletterSignup
        ...ParagraphTextCtaImage
        ...ParagraphsTwoCardsGradientBorder
        ...ParagraphsQuoteStatement
        
        ...ParagraphsVideoAndText
        ...ParagraphsBlogPostsCards
        ...ParagraphsEventListing
        
      }
    }
  }
}`;
